//
// main-content.scss
// Dashkit component
//

// Main content
//
// General styles

.navbar-vertical:not([style*="display: none"]) ~  .main-content,
.navbar-vertical-sm:not([style*="display: none"]) ~  .main-content {
  
  .container,
  .container-fluid {

    @include media-breakpoint-up(md) {
      padding-left: ($main-content-padding-x + $grid-gutter-width * .5) !important;
      padding-right: ($main-content-padding-x + $grid-gutter-width * .5) !important; 
    }
  }
}


// Main content offset
//
// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {
  
  @each $breakpoint, $value in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {

        // Left

        &.fixed-start:not([style*="display: none"]) ~ .main-content {
          margin-left: $navbar-vertical-width;
        }

        // Right

        &.fixed-end:not([style*="display: none"]) ~ .main-content {
          margin-right: $navbar-vertical-width;
        }
      }
    }
  }
}

.navbar-vertical-sm.navbar-expand {
  
  @each $breakpoint, $value in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {

        // Left

        &.fixed-start:not([style*="display: none"]) ~ .main-content {
          margin-left: $navbar-vertical-width-sm;
        }

        // Right

        &.fixed-end:not([style*="display: none"]) ~ .main-content {
          margin-right: $navbar-vertical-width-sm;
        }
      }
    }
  }
}


@media (max-width: 767px){
  .sucess-message{
    img{
      width: 100% !important;
    }
  }
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}