//
// card.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.card {
  margin-bottom: $card-margin-bottom;
  border-color: $card-outline-color;
  box-shadow: var(--#{$prefix}card-box-shadow);
}

.card > * {
  flex-shrink: 0;
}

// Header

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $card-header-height;
  padding-top: calc(var(--#{$prefix}card-spacer-y) * 0.5);
  padding-bottom: calc(var(--#{$prefix}card-spacer-y) * 0.5);

  > *:first-child {
    flex: 1;
  }
}

.card-header + .card-footer {
  border-top-width: 0;
}

//
// Theme ===================================
//

// Card header
//
// Make sure the card header is always the same height with its content
// centered vertically

.card-header-title {
  margin-bottom: 0;
}

.card-header-tabs {
  margin-top: -$card-spacer-y * 0.5;
  margin-bottom: -$card-spacer-y * 0.5;
  margin-left: 0;
  margin-right: 0;
}

.card-header-tabs .nav-link {
  padding-top: calc((#{$card-header-height} - 1em * #{$line-height-base}) * 0.5) !important;
  padding-bottom: calc((#{$card-header-height} - 1em * #{$line-height-base}) * 0.5) !important;
}

.card-header-tabs .nav-link.active {
  border-bottom-color: var(--#{$prefix}primary);
}

// Card table
//
// Make sure the card table content is aligned with the rest of the card content

.card-table {
  margin-bottom: 0;
}

.card-table thead th {
  border-top-width: 0;
}
.card-table thead th,
.card-table tbody td {

  &:last-child {
    padding-right: var(--#{$prefix}card-spacer-x) !important;
  }
}

.card > .card-table:first-child,
.card > .table-responsive:first-child > .card-table {
  > thead,
  > tbody,
  > tfoot {
    &:first-child {
      > tr:first-child {
        > th,
        > td {
          &:first-child {
            border-top-left-radius: var(--#{$prefix}card-border-radius);
          }

          &:last-child {
            border-top-right-radius: var(--#{$prefix}card-border-radius);
          }
        }
      }
    }
  }
}

// Card avatar
//
// Moves card avatar up by 50%

.card-avatar {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.card-avatar-top {
  margin-top: -($card-spacer-x + $avatar-size-base * 0.5);
}

.card-avatar-top.avatar-xs {
  margin-top: -($card-spacer-x + $avatar-size-xs * 0.5);
}

.card-avatar-top.avatar-sm {
  margin-top: -($card-spacer-x + $avatar-size-sm * 0.5);
}

.card-avatar-top.avatar-lg {
  margin-top: -($card-spacer-x + $avatar-size-lg * 0.5);
}

.card-avatar-top.avatar-xl {
  margin-top: -($card-spacer-x + $avatar-size-xl * 0.5);
}

.card-avatar-top.avatar-xxl {
  margin-top: -($card-spacer-x + $avatar-size-xxl * 0.5);
}

// Card dropdown
//
// Places dropdowns in the top right corner

.card-dropdown {
  position: absolute;
  top: var(--#{$prefix}card-spacer-x);
  right: var(--#{$prefix}card-spacer-x);
}

// Card inactive
//
// Adds dashed border and removes background

.card-inactive {
  border-color: var(--#{$prefix}border-color);
  border-style: dashed;
  background-color: transparent;
  box-shadow: none;
}

// Card flush
//
// Removes borders and background

.card-flush {
  background: none;
  border: none;
  box-shadow: none;
}

// Card sizing
//
// Affects card padding

.card-sm .card-body,
.card-sm .card-footer-boxed {
  padding: $card-spacer-x-sm;
}

// Card header flush
//
// Removes border between card header and body and adjusts spacing

.card-header-flush {
  border-bottom: 0;
}

.card-header-flush + .card-body {
  padding-top: 0;
}

// Card fill
//
// Fills the whole available space to fit the height of the neighboring card

.card-fill {
  height: calc(100% - #{$card-margin-bottom});
}

.card-fill .card-body {
  flex-grow: 0;
  margin-top: auto;
  margin-bottom: auto;
}

// Responsive

.card-fill {
  @each $breakpoint, $value in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        height: calc(100% - #{$card-margin-bottom});

        .card-body {
          flex-grow: 0;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
}

// Card adjust
//
// Limits the height to the height of the neighboring card and adds a scrollbar to the card body

.card-adjust {
  height: 0;
  min-height: 100%;
}

.card-adjust .card {
  height: calc(100% - #{$card-margin-bottom});
}

.card-adjust .card-body {
  height: calc(100% - #{$card-header-height});
  overflow-y: auto;
}

// Responsive

.card-adjust {
  @each $breakpoint, $value in $grid-breakpoints {
    &-#{$breakpoint} {
      @include media-breakpoint-up(#{$breakpoint}) {
        height: 0;
        min-height: 100%;

        .card {
          height: calc(100% - #{$card-margin-bottom});
        }

        .card-body {
          height: calc(100% - #{$card-header-height});
          overflow-y: auto;
        }
      }
    }
  }
}

// Card footer boxed

.card-footer-boxed {
  padding-right: 0;
  padding-bottom: var(--#{$prefix}card-spacer-x);
  padding-left: 0;
  margin-left: var(--#{$prefix}card-spacer-x);
  margin-right: var(--#{$prefix}card-spacer-x);
}

// Card pagination

.card-pagination.pagination-tabs {
  margin-top: -$card-cap-padding-y;
  margin-bottom: -$card-cap-padding-y;
  border: 0;
}

.card-pagination.pagination-tabs .page-link {
  border-top-width: 0 !important;
}

.card-pagination.pagination-tabs .active .page-link {
  border-width: 0 0 var(--#{$prefix}border-width) 0 !important;
}

// Card divider

.card-divider {
  border-color: var(--#{$prefix}card-border-color);
}

//Table Card
.table-card{
  .card-header{
    .header-title{
      display: flex;
      align-items: center;
      color: #45648d;
      i{
        margin-right:5px;
      }
    }
  }
}
.table-title {
  display: flex;
  align-items: center;
  color: #6e84a3;
  margin-bottom: 20px;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
}
//Breadcrumb
.card-breadcrumb{
  border-radius: 5px !important;
  .card-body{
    padding: 7px 20px 7px 20px !important;
    .breadcrumb-item{
      &:before{
        color: #bbbbbb !important;
        padding-right: 5px !important;
      }
      a{
        color: #bbbbbb;
        font-size: 13px;
      }
    }

  }
  .page-breadcrumb{
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    display: flex;
    align-items: center;
    li{
      list-style-type: none;
      margin-right: 5px;
      display: flex;
      align-items: center;
      i{
        color: #bbbbbb;
      }
      i.fe-home{
        margin-right: 5px;
        color: #bbbbbb;
      }
    }
  }
}
.modal{
  .card-table{
    th{
      &:last-child{
        padding-right: 10px !important;
      }
      &:first-child{
        padding-left: 10px !important;
      }
    }
    td{
      &:last-child{
        padding-right: 10px !important;
      }
      &:first-child{
        padding-left: 10px !important;
      }
    }
  }
}

@media (max-width: 767px){
  .card-header{
    height: auto !important;
  }
}

/* Full Screen */
.full-screen-card {
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: scroll;
  margin: 0 !important;
}

.tms-color{
  color: #C0474F !important;
}
.tms-background{
  background-color: #C0474F !important;
}
.tms-button{
  background-color: #C0474F !important;
  color: #fff;
  border-color: #C0474F !important;

}
.tms-setup-panel{
  .step-number-active{
    background-color: #C0474F !important;
  }
}
.tms-step-title{
  color: #C0474F !important;
}
.nav-tabs{
  li{
    &.active{
      .tms-step-title{
        color: #555 !important;
      }
    }
  }
}