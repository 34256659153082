//
// offcanvas.scss
//

// Header

.offcanvas-header {
  padding: $offcanvas-header-padding-y $offcanvas-header-padding-x;
  border-bottom: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color);
}
