/* Multi Select özel css  */
.flyco-ms-ul {
  width: 100%;
  height: 200px;
  border-radius: 5px !important;
  border: 1px solid #c2cad8;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}
.flyco-ms-ul li {
  padding: 5px;
  border-bottom: 1px solid #e2e2e2;
  border-radius: 0;
  list-style-type: none;
  display: grid;
  cursor: pointer;
}
.flyco-ms-ul li[disabled] {
  pointer-events: none;
  background-color: #ddd;
  opacity: .7;
}
.flyco-ms{
  display: flex;
  justify-content: center;
  align-items: center;
  &:nth-child(2){
    margin-right: 0 !important;
  }
}
.flyco-ms-search-input {
  width: 100%;
  height: 34px;
  margin-bottom: 10px;
  border: 1px solid #c2cad8;
  border-radius: 5px !important;
  padding-left: 5px;
}
.flyco-ms-select-box {
  display: grid;
  margin-right: 10px;
  width: 50%;
  &:nth-child(2){
    margin-right: 0 !important;
  }
}
.flyco-ms-select-box-hidden {
  display:none;
}
.flyco-ms-select-btn {
  width: 100px;
  margin-top: 10px;
  border-radius: 5px;
}
select option.change::after{
  content: attr(data-content);
  color: red !important;
  padding-left: 5px;
}
.t-multiple .badge{
  background-color: #707070;
  border-radius: 20px;
  color: #fff;
  padding: 3px 8px 3px 8px;
  font-size: 13px;
  width: fit-content !important;
}
.flyco-ms-item{
  display: none;
}
/* Multi Select Loader */
#datatable-preloader{
  width: 100% !important;
}
.preloader{
  width: 80%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.loader{
  display: flex;
}
.rounded-circle{
  border-radius: 100% !important;
  margin-right: 5px;
  background-color: #337ab7 !important;
}
.tms-datatable-loader{
  .rounded-circle{
    background-color: #C0474F !important;
  }
}
@keyframes ScaleInOut {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes Rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dot1 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
}

.dot2 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
}

.dot3 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
}