.help-box{
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 5px;
  .help-tooltips{
    width: 25px;
    height: 25px;
    border-radius: 100%;
    line-height: 25px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }
  .info-box{
    display: none;
    z-index: 9999;
    background-color: #fff;
    border-radius: 10px !important;
    left: 35px !important;
    bottom: 10px !important;
    position: absolute;
    padding: 5px;
    width: 300px;
    text-align: center;
    box-shadow: 0 1px 10px 0 rgb(50 50 50 / 20%);
  }
}
.help-box:hover .info-box{
  display: block !important;
}

.help-block{
  color: #e63758;
  padding-top: 10px;
}