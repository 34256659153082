//
// tables.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//
.table thead th {
  background-color: $table-head-bg;
  text-transform: uppercase;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  letter-spacing: 0.08em;
  color: $table-head-color;
}

.table thead th,
tbody th,
tbody td {
  vertical-align: middle;
}

.table td,
.table th {
  border-top: $table-border-width solid var(--#{$prefix}table-border-color);
  border-bottom: 0;
}

.table > :not(:first-child) {
  border-top: 0;
}

// Sizing

.table-sm {
  font-size: $font-size-sm;
}

.table-sm thead th {
  font-size: $font-size-xs;
}

//
// Theme =====================================
//

// No wrap
//
// Prevents table content from wrapping to the next line

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

// Sort
//
// Adds sorting icons

.table [data-sort] {
  white-space: nowrap;

  &::after {
    content: str-replace(
      url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$gray-600}'/></svg>"),
      '#',
      '%23'
    );
    margin-left: 0.25rem;
  }
}

// Links

.table a[class*='text-reset']:hover {
  color: var(--#{$prefix}link-color) !important;
}

//Default Table
.default-table{
  border: 1px solid #e0ecfd !important;
  td,th{
    padding: 8px 10px !important;
    border-right: 1px solid;
    border-color: #e0ecfd !important;
    font-size: 13px !important;
  }
}

//DataTable
table.dataTable{
  border: 1px solid #e0ecfd;
  border-radius: 5px;
  background-color: #fff !important;
}
table.dataTable thead th, table.dataTable thead td{
  border-bottom: 1px solid #e0ecfd !important;
  background-color: #f9fbfd !important;
  color: #4d6c93 !important;
  font-size: 13px !important;
}
table.dataTable thead th a{
  color: #4d6c93 !important;
}
table.dataTable.stripe>tbody>tr.odd>*, table.dataTable.display>tbody>tr.odd>*,
table.dataTable.display>tbody>tr.odd>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.odd>.sorting_1,
table.dataTable.order-column>tbody tr>.sorting_1, table.dataTable.order-column>tbody tr>.sorting_2, table.dataTable.order-column>tbody tr>.sorting_3, table.dataTable.display>tbody tr>.sorting_1, table.dataTable.display>tbody tr>.sorting_2, table.dataTable.display>tbody tr>.sorting_3,
table.dataTable.display>tbody>tr.even>.sorting_1, table.dataTable.order-column.stripe>tbody>tr.even>.sorting_1{
  box-shadow: none !important;
}
table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td,
table.dataTable.no-footer{
  border-color: #e0ecfd !important;
  border-right: 1px solid;
}
table.dataTable.display tbody td:last-child,
table.dataTable thead th:last-child{
  border-right: none !important;
}
table.dataTable .table-action{
  text-align: center;
}
table.dataTable thead th{
  border-right: 1px solid #e0ecfd !important;
}
table.dataTable td {
  width: -moz-max-content;
  width: max-content;
  font-size: 13px !important;
  color: #333 !important;
}
table.dataTable td.text-danger {
  color: #dc3545 !important;
}
.dataTables_filter{
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}
.table-vertical{
  border: 1px solid #e0ecfd;
  border-radius: 5px !important;
  background-color: #fff !important;
}
.table-vertical th{
  background-color: #f9fbfd;
  color: #95aac9 !important;
  border-right: 1px solid #e0ecfd;
}
.dataTables_info{
  font-size: 13px;
  color: #95aac9 !important;
}
.table-center .tbody tr td{
  text-align: center;
}
.table-vertical{
  border: 1px solid #e0ecfd;
  border-radius: 5px;
}
.table-vertical th{
  background-color: #f9fbfd;
  color: #4e6c93 !important;
  border-right: 1px solid #e0ecfd;
}
.table-vertical td{
  padding: 10px !important;
}
.dataTables_info{
  font-size: 13px;
  color: #95aac9 !important;
}
.table-vertical .button-col{
  border-left: 1px solid #e0ecfd;
}
//Datatable entry
.dataTables_length{
  margin-bottom: 10px;
  select{
    border-color: #dfe2e5 !important;
    border-radius: 5px !important;
  }
}
//Datatable Filter
.dataTables_filter{
  input{
    border-color: #dfe2e5 !important;
    border-radius: 5px !important;
  }
}
//Datatable Pagination
.dataTables_paginate .previous,
.dataTables_paginate .next{
  display: none !important;
}
.dataTables_paginate{
  border: 1px solid #E3EBF6 !important;
  padding-top: 0 !important;
  border-radius: 5px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.25 !important;
  .paginate_button{
    border-right: 1px solid #E3EBF6 !important;
    &:hover{
      background: #e0edff !important;
      border-color: #e0edff !important;
    }
    &.current{
      margin-left: 0 !important;
      border-color: #2C7BE5 !important;
      background-color: #2C7BE5 !important;
      color: #fff !important;
    }
    &:first-child{
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    &:last-child{
      border-right: none !important;
      border-left: 1px solid #E3EBF6 !important;;
      margin-left: 0 !important;
    }
  }
}
//Tablo Filtreleme
.filter-box{
  padding: 20px;
  .filter-input{
    padding-bottom: 10px;
  }
  .filter-action{
    text-align: right;
  }
}

.table-buttons-row{
  display: flex;
  align-items: center;
}
.table-buttons-row button{
  margin-left: 5px;
}
//custom pagination
.pagination{
  float: right;
  flex-wrap: wrap;
  justify-content: right;
  .results{
    width: 100%;
    text-align: right;
    font-size: 13px;
  }
}

.column-break{
  white-space: break-spaces !important;
}

.vertical-baseline{
  vertical-align: baseline;
}

.has-error{
  label{
    color: #E63757;
  }
  input,textarea,select,.flyco-ms-ul{
    border-color: #E63757;
  }
}
.column-inherit{
  white-space: inherit !important;
}

@media (max-width: 767px){
  .table-buttons-row{
    margin-top: 10px;
  }
  .pagination{
    float: inherit;
    overflow-x: scroll;
  }
  .dataTables_filter{
    text-align: left !important;
  }
  .dataTables_length{
    text-align: left !important;
  }
}

.fa-sort-desc{
  font-family: "Feather" !important;
  font-style: normal !important;
  &:before{
    content: "\e92e" !important;
  }
}
.fa-sort-asc{
  font-family: "Feather" !important;
  font-style: normal !important;
  &:before{
    content: "\e931" !important;
  }
}
.w-150{
  width: 150px;
}

table{
  th{
    a{
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      align-items: center;
      aside{
        margin-left: 5px;
      }
    }
  }
}

table{
  td{
    &.btn-success{
      background-color: #00D97E !important;
    }
  }
}