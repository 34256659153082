//
// progress.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Rounds the progress bar, even for "multiple bar" progress bars
.progress-bar:first-child {
  border-top-left-radius: var(--#{$prefix}progress-border-radius);
  border-bottom-left-radius: var(--#{$prefix}progress-border-radius);
}
.progress-bar:last-child {
  border-top-right-radius: var(--#{$prefix}progress-border-radius);
  border-bottom-right-radius: var(--#{$prefix}progress-border-radius);
}

//
// Theme ===================================
//

.progress {
  --#{$prefix}progress-height-sm: #{$progress-height-sm};
}

.progress-sm {
  height: var(--#{$prefix}progress-height-sm);
}
