//
// modal.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =======================
//

.modal-dialog {
  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transform: translate(0, -150px);
  }

  .modal.show & {
    transform: translate(0, 0);
  }
}

.modal-header .btn-close {
  margin: -1.5rem -1.5rem -1.5rem auto;
}

//
// Theme ===================================
//

.modal {
  --#{$prefix}modal-card-body-max-height: #{$modal-card-body-max-height};
}

// Modal card

.modal-card {
  margin-bottom: 0;

  .card-body {
    max-height: var(--#{$prefix}modal-card-body-max-height);
    overflow-y: auto;
  }
}

// Modal tabs

.modal-header-tabs {
  margin-top: -$modal-header-padding-y;
  margin-bottom: calc(#{$modal-header-padding-y} * -1 - #{$border-width});
}

.modal-footer{
  padding: 10px !important;
}
