//Loder Animation
.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #25427d;
  margin-right: 5px;
}
.load .line:nth-last-child(1) {
  animation: loadingB 1.5s 1s infinite;
}
.load .line:nth-last-child(2) {
  animation: loadingB 1.5s 0.5s infinite;
}
.load .line:nth-last-child(3) {
  animation: loadingB 1.5s 0s infinite;
}
.load-wrapp {
  width: 100%;
  height: 100%;
  margin: 10px 10px 10px 0;
  padding: 20px 20px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-wrapp:last-child {
  margin-right: 0;
}
@keyframes loadingB {
  0% {
    width: 15px;
  }
  50% {
    width: 35px;
  }
  100% {
    width: 15px;
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
