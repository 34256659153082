body{
  margin: 0 !important;
  padding-bottom: 0 !important;
}
nav,
.navbar-vertical{
  display: none !important;
}
.login-main{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .login-form-box{
    width: 30%;
    background-color: rgb(210 230 248 / 92%);
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 1px 10px 0 rgba(50,50,50,.2);
  }
  .btn-login{
    transition: 0.5s;
    background-color: #25427d;
    border-color: #25427d;
    font-size: 14px;
  }
  .btn-login:hover,
  .btn-login:focus {
    transition: 0.5s;
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
  }
  .input-group-text{
    background-color: #e8f0fe;
  }
  input{
    font-size: 13px !important;
  }
  .forgot-password-txt{
    font-size: 12px;
    text-decoration: none;
  }
  .show-password-icon{
    text-decoration: none;
    a{
      color: #6c757d;
    }
  }
}
#password-policy-info, {
  position: absolute;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  top: 15px;
  display: none;
}
.alert {
  position: absolute;
  width: calc(100% - 45px);
  margin-left: 15px;
  margin-right: 15px;
  top: 15px;
  z-index: 2;
}
.container-fluid{
  margin-top:0 !important;
}
.alert-password-danger{
  right: 0;
}

.card-deck{
  display: flex;
  flex-flow: row wrap;
  margin-right: -12px;
  margin-left: -12px;
  .card{
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
    border-color: #edf2f9;
    box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03);
  }
}

@media (max-width: 767px){
  .login-main{
    .login-form-box{
      width: 100%;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px)  {
  .login-main{
    .login-form-box{
      width: 50%;
    }
  }
}
.password-show{
  display: none;
}

.company-logo-card{
  display: flex;
  flex-flow: row wrap;
  margin-right: -12px;
  margin-left: -12px;
  justify-content: center;
  .card {
    flex-basis: calc(20% - 5px);
    margin-right: 12px;
    margin-bottom: 12px;
    margin-left: 12px;
    border-color: #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  }
}
