.step-nav {
  width: 100%;
  justify-content: center;
  padding: 0;
  border: none;

  li {
    flex: calc(100 / 5);
    .step-header-box{
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      .step-title{
        width: 100%;
      }
      .line{
        width: 100%;
        height: 3px;
        background-color: #6e84a347;
        margin-top: 10px;
        &:after{
          content: "";
          width: 0px;
          height: 3px;
          background-color: #25427d;
          display: block;
        }
      }
      .step-number{
        width: 35px;
        height: 35px;
        line-height: 35px;
        background-color: #6e84a347;
        border-radius: 100%;
        color: #fff;
        margin-bottom: 10px;
      }
    }

    &.active{
      .active-line{
        &:after{
          content: "";
          width: 100%;
          height: 3px;
          background-color: #25427d;
          display: block;
          -webkit-transition: width 1s ease-in-out;
          -moz-transition: width 1s ease-in-out;
          -o-transition: width 1s ease-in-out;
          transition: width 1s ease-in-out;
        }
      }
      .step-number-active{
        background-color: #25427d;
      }
    }

  }
}
.tms-setup-panel{
  .line{
    &.active-line{
      &:after{
        background-color: #C0474F !important;
      }
    }
  }
}
@media (max-width: 767px){
  .step-nav{
    li{
      flex: 30% !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px)  {
  .step-nav{
    li{
      flex: 30% !important;
    }
  }
}

.tms-horizontal-menu{
  li{
    .nav-link{
      &.active{
        border-bottom-color: #c0474f !important;
      }
      &:hover{
        border-color: #c0474f !important;
      }
    }
  }
}




