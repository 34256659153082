//
// buttons.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Sizing

.btn-lg {
  line-height: $btn-line-height-lg;
}

.btn-sm {
  line-height: $btn-line-height-sm;
}

//
// Theme ===================================
//

// Button white

.btn-white {
  --#{$prefix}btn-bg: var(--#{$prefix}white);
  --#{$prefix}btn-border-color: var(--#{$prefix}gray-300);
  --#{$prefix}btn-hover-bg: var(--#{$prefix}gray-100);
  --#{$prefix}btn-hover-border-color: var(--#{$prefix}gray-400);
  --#{$prefix}btn-active-bg: var(--#{$prefix}gray-100);
  --#{$prefix}btn-active-border-color: var(--#{$prefix}gray-400);
}

.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active,
.btn-group-toggle .btn-check:checked + .btn-white {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  box-shadow: none;
  color: var(--#{$prefix}primary);
}

.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}

// Button white 20

.btn-white-20 {
  --#{$prefix}btn-bg: #{rgba($white, 0.2)};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-color: var(--#{$prefix}white);
  --#{$prefix}btn-hover-bg: #{rgba($white, 0.12)};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-hover-color: var(--#{$prefix}white);
  --#{$prefix}btn-active-bg: #{rgba($white, 0.12)};
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-active-color: var(--#{$prefix}white);
}

// Button outline secondary

.btn-outline-secondary {
  &:not(:hover):not([aria-expanded='true']):not([aria-pressed='true']) {
    border-color: var(--#{$prefix}gray-400);
  }
}

// Button rounded
//
// Creates circle button variations

.btn-rounded-circle {
  width: calc(1em * #{var(--#{$prefix}btn-line-height)} + #{$input-btn-padding-y * 2} + #{var(--#{$prefix}btn-border-width)} * 2);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn-rounded-circle.btn-lg {
  width: calc(1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2} + #{var(--#{$prefix}btn-border-width)} * 2);
}

.btn-rounded-circle.btn-sm {
  width: calc(1em * #{$btn-line-height-sm} + #{$input-btn-padding-y-sm * 2} + #{var(--#{$prefix}btn-border-width)} * 2);
}

//Button Soft
.btn-primary-soft{
  background-color: #d5e5fa;
  color: #2C7BE5;
}
.btn-success-soft{
  background-color: #ccf7e5;
  color: #00D97E;
}
.btn-secondary-soft{
  background-color: #e2e6ed;
  color: #6E84A3;
}
.btn-info-soft{
  background-color: #d7eff6;
  color: #39afd1;
}
.btn-warning{
  color: #fff !important;
}
.btn-warning-soft{
  background-color: #ffe7aa;
  color: #eaad13;
}
.btn-danger-soft{
  background-color: #fad7dd;
  color: #E63757;
}
.btn-light-soft{
  background-color: #fbfcfe;
  color: #EDF2F9;
}
.btn-dark-soft{
  background-color: #d0d4d9;
  color: #12263F;
}
//Button Soft Hover
.btn-primary-soft:hover{
  color: #d5e5fa;
  background-color: #2C7BE5;
}
.btn-success-soft:hover{
  color: #ccf7e5;
  background-color: #00D97E;
}
.btn-secondary-soft:hover{
  color: #e2e6ed;
  background-color: #6E84A3;
}
.btn-info-soft:hover{
  color: #d7eff6;
  background-color: #39afd1;
}
.btn-warning-soft:hover{
  color: #fdf3d9;
  background-color: #F6C343;
}
.btn-danger-soft:hover{
  color: #fad7dd;
  background-color: #E63757;
}
.btn-light-soft:hover{
  color: #fbfcfe;
  background-color: #EDF2F9;
}
.btn-dark-soft:hover{
  color: #d0d4d9;
  background-color: #12263F;
}
.bg-grey-soft{
  background-color: #bac3d0 !important;
}
.bg-dark-grey-soft{
  background-color: #aab2be !important
}
.bg-light-grey-soft{
  background-color: #bdbdbd52 !important;
  color: #262626 !important;
}
.bg-purple{
  background-color: #6d32a8 !important;
  color: #fff !important;
}


//Widget Color
.bg-orange-border{
  border-left-color: #ff7f50 !important;
  border-right-color: #ff7f50 !important;
}
.bg-orange{
  background-color: #ff7f50 !important;
  color: #fff !important;
  i.fe{
    color: #fff !important;
  }
}
.bg-orange-soft{
  background-color: #ff7f5036 !important;
  i.fe{
    color: #ff7f50 !important;
  }
}
.bg-green-border{
  border-left-color: #8fce00 !important;
  border-right-color: #8fce00 !important;
}
.bg-green{
  background-color: #8fce00 !important;
  color: #fff !important;
  i.fe{
    color: #fff !important;
  }
}
.bg-green-soft{
  background-color: #8fce003d !important;
  i.fe{
    color: #8fce00 !important;
  }
}

.bg-pink-border{
  border-left-color: #f4cccc !important;
  border-right-color: #f4cccc !important;
}
.bg-pink-soft{
  background-color: rgba(244, 204, 204, 0.3) !important;
  color: #f4cccc !important;
  i.fe{
    color: #f7b9b9 !important;
  }
}

.bg-purple-soft{
  background-color: #6d32a8 !important;
  color: #fff !important;
  i.fe{
    color: #fff !important;
  }
}

.bg-yellow-soft{
  background-color: rgba(217, 210, 20, 0.3) !important;
  color: #d9d214 !important;
  i.fe{
    color: #d9d214 !important;
  }
}
.bg-warning{
  color: #fff !important;
}
.bg-default{
  background-color: #bac3d0 !important;
}
.bg-info{
  color: #fff !important;
}
.btn-info,
.btn-primary,
.btn-danger,
.btn-success{
  color: #fff !important;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev{
  text-align: center;
}
.ui-datepicker .ui-datepicker-next::after{
  content: "\e930";
  font-family: "Feather" !important;
}
.ui-datepicker .ui-datepicker-prev::after{
  content: "\e92f";
  font-family: "Feather" !important;
}