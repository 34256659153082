.sortable{
  padding-left: 0;
  li{
    list-style-type: none;
    border-color: #dddddd !important;
    background-color: #f8f8f8 !important;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px !important;
  }
}