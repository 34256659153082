// 
// form-control.scss
// Extended from Bootstrap
//

//
// Bootstrap overrides ===================================
//

// Sizing

.form-control-sm {
  line-height: $input-line-height-sm;
}

.form-control-lg {
  line-height: $input-line-height-lg;
}


//
// Theme ===================================
//

// Form control flush
//
// Removes borders and paddings from inputs and text areas

.form-control-flush {
  padding-left: 0;
  border-color: transparent !important;
  background-color: transparent !important;
  resize: none;
}

.form-control:not(.form-select) {
  padding-right: 0;
}

.form-select{
  cursor: pointer !important;
}
// Form control auto
//
// Sets the height of the input to auto

.form-control-auto {
  min-height: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
}


// Form control rounded
//
// Makes input border radius round

.form-control-rounded {
  border-radius: 20rem;
}


// Font size

.form-control {

  &.h1, &.h2, &.h3, &.h4, &.h5 {
    margin-bottom: 0;
    font-weight: $headings-font-weight;
    letter-spacing: $headings-letter-spacing;
  }

  &.h1 {
    font-size: $h1-font-size;
  }

  &.h2 {
    font-size: $h2-font-size;
  }

  &.h3 {
    font-size: $h3-font-size;
  }

  &.h4 {
    font-size: $h4-font-size;
  }

  &.h5 {
    font-size: $h5-font-size;
  }
}

//Select2

.course-exam-select2{
  .select2-selection--single{
    min-height: 40px !important;
  }
}

.select2-container{
  width: 100% !important;
  .select2-selection{
    line-height: 1.5 !important;
    padding:0.5rem 0.75rem !important;
    border-color: #d2ddec !important;
    ul{
      li{
        margin-top: 0 !important;
      }
    }
    &.select2-selection--single{
      height: auto !important;
    }
    .select2-selection__arrow{
      height: 40px !important;
    }
  }
}

.form-multi-select{
  .select2-selection--single{
    height: 40px !important;
    .select2-selection__rendered{
      line-height: normal !important;
      padding-left: 0 !important;
    }
  }
}

.ms-container{
  width: 100% !important;
  .ms-list{
    margin-top: 5px;
  }
}

.form-control[disabled],
input[readonly]{
  background-color: #eaedf1 !important;
  cursor: not-allowed !important;
}

select[disabled]{
  background-color: #eaedf1 !important;
  cursor: not-allowed !important;
}
.select2-container--disabled{
  .select2-selection--single,
  .select2-selection--multiple{
    cursor: not-allowed !important;
    li{
      padding-left: 5px !important;
      margin-left: 0 !important;
      background-color: #fbfbfb !important;
    }
  }
}

textarea[disabled]{
  background-color: #ebedf1 !important;
  cursor: not-allowed;
}

.form-control[disabled], .form-check-input[disabled]{
  cursor:not-allowed !important;
}

.minicolors-theme-bootstrap{
  .minicolors-swatch{
    top: 7px !important;
    left: 5px !important;
  }
}

.assign-training-form{
  .form-group{
    display: flex;
    align-items: center;
    label{
      flex: 0 12%;
    }
    .form-control-col{
      width: 88%;
      .training-filter-select{
        width: calc(50% - 5px);
      }
    }
  }
}
.type_label{
  padding-left: 45px;
  position: relative;
  width: 100%;
  &:before{
    content: "";
    width: 40px;
    height: 1px;
    background: #d2ddec;
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
  }
  &:after{
    content: "";
    width: calc(100% - 165px);
    height: 1px;
    background: #d2ddec;
    position: absolute;
    display: block;
    top: 50%;
    right: 0;
  }
}
.assignment-check{
  label{
    color: #9d9d9d;
  }
}

.form-radio[type="radio"]:checked+label {
  color: #000;
}

.assignment-accordion{
  .accordion-item{
    margin-bottom: 15px;
    box-shadow: 0 1px 10px 0 rgb(180 180 180 / 20%);
    border: 1px solid #eee;
    border-radius: 5px !important;
  }
  .accordion-button{
    padding: 10px;
    text-transform: capitalize;
    font-weight: 500;
    color: #4c515d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after{
      display: none;
    }
  }
  .accordion-body{
    padding: 10px;
  }
}

mark{
  background-color: #ffff00 !important;
  color: #e63757 !important;
}

.wrong{
  background-color: #e63757 !important;
  color: #fff !important;
}
.correct{
  background-color: #5b9909 !important;
  color: #fff !important;
}

.h-40{
  height: 40px !important;
}
